<template>
  <div id="app">
    <redirecting-view
      v-if="isRedirecting"
      :loadingText="loadingText"
    ></redirecting-view>
    <div id="display" v-else>
      <div
        v-if="isAuthenticated"
        :class="{ 'menu-bar': true }"
        class="menu-bar"
      >
        <img
          v-if="!isOnMobile"
          style="margin-top: 25px"
          class="showHover"
          src="./assets/images/cad-assist.png"
        />
        <div style="flex: 1"></div>
        <div
          class="menu-item"
          v-bind:class="{ active: $route.path.includes('/dashboard') }"
          @click="goToDashboard"
          v-if="
            user.roleName != null &&
            user.roleName.String.toLowerCase().includes('admin')
          "
        >
          <img src="./assets/images/statistics.png" />
          <p v-if="!isOnMobile" class="showHover">Dashboard</p>
        </div>
        <div
          class="menu-item"
          v-bind:class="{ active: $route.path === '/' }"
          @click="goToHome"
        >
          <img src="./assets/images/home.png" />
          <p v-if="!isOnMobile" class="showHover">Home</p>
        </div>
        <div
          class="menu-item"
          v-bind:class="{
            active: $route.path === '/agent' || $route.path.includes('/agent/'),
          }"
          @click="goToAgent"
        >
          <img src="./assets/images/chat.png" />
          <p v-if="!isOnMobile" class="showHover">Chat</p>
        </div>
        <!-- <div
          class="menu-item"
          v-bind:class="{ active: $route.path === '/profile' }"
          @click="goToProfile"
        >
          <img src="./assets/images/setting.png" />
          <p v-if="!isOnMobile" class="showHover">Setting</p>
        </div> -->
        <div class="menu-item" @click="reportIssue">
          <img src="./assets/images/mail.png" />
          <p v-if="!isOnMobile" class="showHover">Report</p>
        </div>
        <div style="flex: 1"></div>
        <div style="margin-bottom: 25px" class="menu-item" @click="logout">
          <img src="./assets/images/logout.png" />
          <p v-if="!isOnMobile" class="showHover">Sign Out</p>
        </div>
      </div>
      <div class="top-bar">
        <div></div>
        <div v-if="user.username" class="top-bar-container">
          <div class="top-bar-content">
            <h4 style="margin-right: 24px">
              {{ `Welcome, ${user.username}` }}
            </h4>
            <h6 style="margin-top: 4px; opacity: 0.5">{{ user.email }}</h6>
          </div>
          <div class="circle-container">
            <img
              v-if="!user.localImageProfile"
              src="./assets/images/user.png"
            />
            <img v-else :src="user.localImageProfile" />
          </div>
        </div>
      </div>
      <div class="app-content-container">
        <router-view id="router-view" />
        <div v-if="isLoading" class="loading">
          <div class="overlay"></div>
          <div class="circle"></div>
          <p class="loading-text">L O A D I N G</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import AuthService from "./helpers/AuthService";
import { useStore } from "vuex";
import { GetterTypes } from "./store/getters";
import { ActionTypes } from "./store/actions";
import RedirectingView from "./views/RedirectingView.vue";

export default {
  components: { RedirectingView },
  setup() {
    const isAuthenticated = ref(false);
    const name = ref("Anonymous");
    const router = useRouter();
    const store = useStore();
    const isRedirecting = ref(true);
    const isOnMobile = ref(false);
    const loadingText = ref("Redirecting");

    const checkViewportWidth = () => {
      if (window.innerWidth <= 768) {
        isOnMobile.value = true;
      } else {
        isOnMobile.value = false;
      }
    };

    const user = computed(() => {
      return store.getters[GetterTypes.GET_USER];
    });

    const goToProfile = () => {
      router.push("/profile");
    };

    const isLoading = computed(() => {
      return store.getters[GetterTypes.GET_LOADING];
    });

    const goToHome = () => {
      router.push("/");
    };

    const goToAgent = () => {
      router.push("/agent");
    };

    const goToWhatsNew = () => {
      router.push("/whats-new");
    };

    const goToDashboard = () => {
      router.push("/dashboard");
    };

    const logout = () => {
      AuthService.logout();
    };

    const reportIssue = () => {
      const url = "https://forms.office.com/r/AvHDNccabN?origin=lprLink"; // Replace this with your URL
      window.open(url, "_blank");
    };

    const fetchToken = async () => {
      AuthService.initialize().then(() => {
        AuthService.getToken(false).then((account) => {
          if (account) {
            isAuthenticated.value = true;
            const username = account?.account.username
              .toLowerCase()
              .split("@")[0];
            name.value = username;
            isAuthenticated.value = true;
            loadingText.value = "Fetching Data";
            Promise.all([
              store.dispatch(ActionTypes.CREATE_USER, { username }),
              store.dispatch(ActionTypes.LIST_TAG, username),
              store.dispatch(ActionTypes.LIST_AGENT, username),
            ]).then(() => {
              isRedirecting.value = false;
            });
          } else {
            isRedirecting.value = false;
          }
        });
      });
    };

    onMounted(async () => {
      window.addEventListener("resize", checkViewportWidth);
      fetchToken();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkViewportWidth);
    });

    return {
      isAuthenticated,
      name,
      goToProfile,
      goToHome,
      goToAgent,
      goToWhatsNew,
      goToDashboard,
      logout,
      isLoading,
      reportIssue,
      isOnMobile,
      isRedirecting,
      loadingText,
      user,
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100..900&family=Pridi:wght@200;300;400;500;600;700&family=Protest+Guerrilla&family=Sixtyfour&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Honk&display=swap");

* {
  font-family: Ubuntu, "Noto Sans Thai", sans-serif;
  margin: 0;
}

#app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  user-select: none;
}

#display {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  user-select: none;
}

#router-view {
  flex: 1;
}

.circle-container {
  position: relative; /* Position the plus sign relative to the container */
  width: 50px; /* Set the width of the container */
  height: 50px; /* Set the height of the container */
  overflow: hidden; /* Hide any overflow */
  border-radius: 50%; /* Make the container circular */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.circle-container img {
  width: 100%; /* Make the image fill the container horizontally */
  height: auto; /* Maintain the aspect ratio of the image */
  display: block; /* Make the image a block element */
}

.material-symbols-rounded {
  font-size: 36px;
}

.menu-expand {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 8px;
  opacity: 0.7;
}

.menu-expand span {
  color: #ddd;
  font-size: 16px;
  font-weight: 900;
}

.menu-open {
  position: fixed;
  top: 0;
  left: 4px;
  padding-top: 18px;
  padding-left: 16px;
  font-size: 28px;
  opacity: 0.8;
}

.circular-border {
  border-radius: 25%;
  border: 1px solid #ddd;
  display: inline-block;
  padding: 2px;
  cursor: pointer;
}

.top-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-right: 48px;
}

.top-bar-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}

.menu-bar {
  position: absolute;
  top: 0; /* Adjust as needed */
  left: 0; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-left: 8px;
  padding-right: 8px;
  background-color: rgba(0, 0, 0, 0.9);
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  transition: height 0.5s ease;
  z-index: 1000;
}

.menu-bar img {
  width: 100px;
  align-self: center;
}

.menu-bar.closed {
  height: 0;
  visibility: hidden;
}

.menu-bar:not(.closed) {
  height: 100vh;
  visibility: visible;
}

.menu-bar:hover .showHover {
  position: relative;
  opacity: 1;
  max-height: 100px;
  color: white;
  transition: opacity 1s ease, max-height 1s ease;
}

.showHover {
  position: absolute;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.1s ease, max-height 0.3s ease;
}

.menu-item {
  height: 32px;
  color: #eeeeee;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px 16px;
  display: flex;
  border-radius: 15px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;

  p {
    font-size: 16px;
    margin-left: 16px;
    font-weight: 100;
  }
}

.menu-item img {
  width: 32px;
  height: 32px;
}

.menu-item span {
  font-size: 32px;
}

.menu-item span.small {
  font-size: 14px;
}

.menu-item:hover {
  color: white;
  transition: transform 0.3s ease;
  opacity: 1;
}

.app-content-container {
  margin-left: 7vw;
  margin-right: 5vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
}

.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
  opacity: 1;
}

input::placeholder {
  color: #999;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #a14df2;
  border-bottom-color: #a14df2;
  animation: spin 1s linear infinite;
  background: linear-gradient(to right, #a14df2, #449de5);
}

.loading-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 16px;
  font-weight: 100;
  color: #eeeeee;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .app-content-container {
    margin-bottom: 9vh;
  }

  .circle {
    width: 50px;
    height: 50px;
  }

  .top-bar-container {
    margin-right: 8px;
    margin-top: 8px;
  }

  .top-bar-content {
    h4 {
      font-size: 12px;
    }

    h6 {
      font-size: 10px;
    }
  }

  .menu-bar {
    flex-direction: row;
    bottom: 0;
    top: auto;
    width: 100vw;
    height: 9vh !important;
    padding-left: 0;
    padding-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .menu-item img {
    width: 25px;
    height: 25px;
  }

  .app-content-container {
    margin-left: 0;
    margin-right: 0;
  }

  .menu-item {
    height: 25px;
  }

  .menu-item img {
    width: 25px;
    height: 25px;
  }

  .expand-icon {
    display: none;
  }

  .menu-item span {
    font-size: 28px;
  }

  #app {
    overflow: hidden;
  }

  #display {
    overflow: hidden;
  }

  .menu-open {
    padding-top: 18px !important;
  }
}

@media (max-width: 992px) {
  .menu-open {
    padding-top: 28px;
  }
}
</style>
