import { ActionContext, ActionTree } from "vuex";
import { RootState } from "./state";
import { MutationTypes } from "./mutations";
import {
  AgentModel,
  UserModel,
  RoomModel,
  TagModel,
  MetadataModel,
} from "../types";
import httpRequest from "../helpers/ApiService";
import { SessionModel } from "@/types/session";
import { ChatModel } from "@/types/chat";
import { RoleModel } from "@/types/role";
import { DashboardModel } from "@/types/dashboard";
import { FeedbackModel } from "@/types/feedback";

export enum ActionTypes {
  // DASHBOARD
  FETCH_DASHBOARD = "FETCH_DASHBOARD",
  // USERS
  CREATE_USER = "CREATE_USER",
  CREATE_TAG = "CREATE_TAG",
  UPDATE_TAG = "UPDATE_TAG",
  LIST_USER = "LIST_USER",
  UPLOAD_IMAGE_PROFILE = "UPLOAD_IMAGE_PROFILE",
  UPDATE_USER_ROLE = "UPDATE_USER_ROLE",
  UPDATE_USER = "UPDATE_USER",
  // AGENTS
  GET_AGENT = "GET_AGENT",
  CREATE_AGENT = "CREATE_AGENT",
  LIST_AGENT = "LIST_AGENT",
  UPDATE_AGENT = "UPDATE_AGENT",
  DELETE_AGENT = "DELETE_AGENT",
  // ROOMS
  CREATE_ROOM = "CREATE_ROOM",
  // SESSIONS
  LIST_SESSION = "LIST_SESSION",
  CREATE_SESSION = "CREATE_SESSION",
  UPDATE_SESSION = "UPDATE_SESSION",
  DELETE_SESSION = "DELETE_SESSION",
  // CHATS
  LIST_CHAT = "LIST_CHAT",
  CREATE_CHAT = "CREATE_CHAT",
  DUMMY_CHAT = "DUMMY_CHAT",
  // TAGS
  LIST_TAG = "LIST_TAG",
  SELECTED_TAG = "SELECTED_TAG",
  // AGENT TAGS
  LINK_AGENT_TAG = "LINK_AGENT_TAG",
  UNLINK_AGENT_TAG = "UNLINK_AGENT_TAG",
  // ROLES
  CREATE_ROLE = "CREATE_ROLE",
  LIST_ROLE = "LIST_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  // ROLE AGENTS
  LINK_ROLE_AGENT = "LINK_ROLE_AGENT",
  UNLINK_ROLE_AGENT = "UNLINK_ROLE_AGENT",
  // ROLE USER
  LINK_ROLE_USER = "LINK_ROLE_USER",
  UNLINK_ROLE_USER = "UNLINK_ROLE_USER",
  // FEEDBACK
  CREATE_FEEDBACK = "CREATE_FEEDBACK",
  LIST_FEEDBACK = "LIST_FEEDBACK",
  // UTILS
  SEARCH = "SEARCH",
  URL_METADATA = "URL_METADATA",
  LOADING = "LOADING",
}

export type Actions = {
  // DASHBOARD
  [ActionTypes.FETCH_DASHBOARD](context: ActionContext<RootState, any>): void;
  // USERS
  [ActionTypes.CREATE_USER](
    context: ActionContext<RootState, UserModel>,
    payload: {
      username: string;
      imageProfile: string | null;
      lineId: {
        String: string | null;
        Valid: boolean;
      } | null;
      isAdd: boolean | null;
      role: RoleModel | null;
    }
  ): void;
  [ActionTypes.LIST_USER](context: ActionContext<RootState, UserModel[]>): void;
  [ActionTypes.UPLOAD_IMAGE_PROFILE](
    context: ActionContext<RootState, string[]>,
    payload: {
      username: string;
      imageProfile: string;
    }
  ): void;
  [ActionTypes.UPDATE_USER_ROLE](
    context: ActionContext<RootState, string[]>,
    payload: {
      username: string;
      roleId: number;
      roleName: string;
    }
  ): void;
  [ActionTypes.UPDATE_USER](
    context: ActionContext<RootState, UserModel>,
    user: UserModel
  ): void;
  // AGENTS
  [ActionTypes.CREATE_AGENT](
    context: ActionContext<RootState, AgentModel>,
    agent: AgentModel
  ): void;
  [ActionTypes.GET_AGENT](
    context: ActionContext<RootState, AgentModel>,
    id: number
  ): void;
  [ActionTypes.LIST_AGENT](
    context: ActionContext<RootState, AgentModel[]>,
    username: string
  ): void;
  [ActionTypes.UPDATE_AGENT](
    context: ActionContext<RootState, AgentModel>,
    agent: AgentModel
  ): void;
  [ActionTypes.DELETE_AGENT](
    context: ActionContext<RootState, AgentModel>,
    id: number
  ): void;
  // ROOMS
  [ActionTypes.CREATE_ROOM](
    context: ActionContext<RootState, RoomModel>,
    payload: { username: string; agentId: number }
  ): void;
  // SESSIONS
  [ActionTypes.LIST_SESSION](
    context: ActionContext<RootState, SessionModel[]>,
    payload: { roomId: number; limit: number; offset: number }
  ): void;
  [ActionTypes.CREATE_SESSION](
    context: ActionContext<RootState, SessionModel>,
    payload: { roomId: string; name: string }
  ): void;
  [ActionTypes.UPDATE_SESSION](
    context: ActionContext<RootState, SessionModel>,
    payload: {
      sessionId: number;
      name: string;
      serial: string;
      conversationId: string;
    }
  ): void;
  [ActionTypes.DELETE_SESSION](
    context: ActionContext<RootState, string[]>,
    sessiongId: number
  ): void;
  // CHATS
  [ActionTypes.LIST_CHAT](
    context: ActionContext<RootState, ChatModel[]>,
    payload: {
      sessionId: number;
      limit: number;
      offset: number;
      isLoading: boolean;
    }
  ): void;
  [ActionTypes.CREATE_CHAT](
    context: ActionContext<RootState, ChatModel>,
    payload: {
      sessionId: number;
      text: string;
      roleText: string;
      dummyId: number;
      imageUrlString: string;
      previewLinkString: string;
    }
  ): void;
  [ActionTypes.DUMMY_CHAT](
    context: ActionContext<RootState, ChatModel>,
    chat: ChatModel
  ): void;
  // FEEDBACKS
  [ActionTypes.CREATE_FEEDBACK](
    context: ActionContext<RootState, string[]>,
    payload: {
      username: string;
      chatHistoryId: number;
      isLike: boolean;
      star: number;
      comment: string;
    }
  ): void;
  [ActionTypes.LIST_FEEDBACK](
    context: ActionContext<RootState, FeedbackModel[]>
  ): void;
  // TAGS
  [ActionTypes.CREATE_TAG](
    context: ActionContext<RootState, TagModel>,
    name: string
  ): void;
  [ActionTypes.UPDATE_TAG](
    context: ActionContext<RootState, TagModel>,
    tag: TagModel
  ): void;
  [ActionTypes.LIST_TAG](
    context: ActionContext<RootState, TagModel[]>,
    username: string
  ): void;
  [ActionTypes.SEARCH](
    context: ActionContext<RootState, string>,
    search: string
  ): void;
  [ActionTypes.SELECTED_TAG](
    context: ActionContext<RootState, string[]>,
    tag: string[]
  ): void;
  // AGENT TAGS
  [ActionTypes.LINK_AGENT_TAG](
    context: ActionContext<RootState, string[]>,
    payload: { agentId: number; tagId: number; tagName: string }
  ): void;
  [ActionTypes.UNLINK_AGENT_TAG](
    context: ActionContext<RootState, string[]>,
    payload: { agentId: number; tagId: number; tagName: string }
  ): void;
  // ROLES
  [ActionTypes.CREATE_ROLE](
    context: ActionContext<RootState, string[]>,
    payload: {
      name: string;
      description: string;
      isActive: boolean;
      agents: AgentModel[];
    }
  ): void;
  [ActionTypes.LIST_ROLE](context: ActionContext<RootState, string[]>): void;
  [ActionTypes.UPDATE_ROLE](
    context: ActionContext<RootState, string[]>,
    role: RoleModel
  ): void;
  [ActionTypes.DELETE_ROLE](
    context: ActionContext<RootState, string[]>,
    id: number
  ): void;
  // ROLE AGENTS
  [ActionTypes.LINK_ROLE_AGENT](
    context: ActionContext<RootState, string[]>,
    payload: { roleId: number; agentId: number; agentName: string }
  ): void;
  [ActionTypes.UNLINK_ROLE_AGENT](
    context: ActionContext<RootState, string[]>,
    payload: { roleId: number; agentId: number; agentName: string }
  ): void;
  // ROLE USER
  [ActionTypes.LINK_ROLE_USER](
    context: ActionContext<RootState, string[]>,
    payload: { roleId: number; roleName: string; username: string }
  ): void;
  [ActionTypes.UNLINK_ROLE_USER](
    context: ActionContext<RootState, string[]>,
    payload: { roleId: number; roleName: string; username: string }
  ): void;
  // UTILS
  [ActionTypes.LOADING](
    context: ActionContext<RootState, boolean>,
    isLoading: boolean
  ): void;
  [ActionTypes.URL_METADATA](
    context: ActionContext<RootState, MetadataModel>,
    url: string
  ): void;
};

export const actions: ActionTree<RootState, any> & Actions = {
  // DASHBOARD
  async [ActionTypes.FETCH_DASHBOARD]({ commit }) {
    try {
      const dashboard = await httpRequest<DashboardModel>("GET", "dashboard");
      commit(MutationTypes.SET_DASHBOARD, dashboard);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // USERS
  async [ActionTypes.CREATE_USER]({ commit }, payload) {
    try {
      const user = await httpRequest<UserModel>("POST", "createUserWithLine", {
        username: payload.username,
        line_id: payload.lineId?.String ? payload.lineId.String : null,
        image_profile: payload.imageProfile,
      });
      if (payload.isAdd) {
        commit(MutationTypes.ADD_USER, user);
        if (payload.role) {
          await httpRequest<UserModel>("POST", "updateUserRole", {
            username: payload.username,
            role_id: payload.role.id,
          });
          commit(MutationTypes.SET_USER_ROLE, {
            username: payload.username,
            roleId: payload.role.id,
            roleName: payload.role.name,
          });
        }
      } else commit(MutationTypes.SET_USER, user);
      return user;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LIST_USER]({ commit }) {
    try {
      const userList = await httpRequest<UserModel[]>("POST", "listUser", {
        limit: 10000,
        offset: 0,
      });
      commit(MutationTypes.SET_USER_LIST, userList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPLOAD_IMAGE_PROFILE]({ commit }, payload) {
    try {
      await httpRequest<ChatModel[]>("POST", "updateImageProfile", {
        username: payload.username,
        image_profile: payload.imageProfile,
      });
      commit(MutationTypes.SET_IMAGE_PROFILE, payload.imageProfile);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPDATE_USER_ROLE]({ commit }, payload) {
    try {
      await httpRequest<UserModel>("POST", "updateUserRole", {
        username: payload.username,
        role_id: payload.roleId,
      });
      commit(MutationTypes.SET_USER_ROLE, {
        username: payload.username,
        roleId: payload.roleId,
        roleName: payload.roleName,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPDATE_USER]({ commit }, payload) {
    try {
      const user = await httpRequest<UserModel>("POST", "updateUser", {
        username: payload.username,
        email: payload.email,
        business_unit: payload.businessUnit,
        company_code: payload.companyCode,
        organization_id: payload.organizationId,
        organization_name: payload.organizationName,
        team: payload.team,
        line_id: payload.lineId?.String ? payload.lineId.String : null,
        is_active: payload.isActive,
        image_profile: payload.imageProfile,
        role_id: payload.roleId?.String ? payload.roleId.String : null,
      });
      commit(MutationTypes.UPDATE_USER, user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // AGENTS
  async [ActionTypes.CREATE_AGENT]({ commit }, payload) {
    try {
      const agent = await httpRequest<AgentModel>("POST", "createAgent", {
        name: payload.name,
        description: payload.description?.String
          ? payload.description.String
          : null,
        api_url: payload.apiUrl,
        authorization_key: payload.authorizationKey,
        image_base64: payload.imageBase64?.String
          ? payload.imageBase64.String
          : null,
        data_source: payload.dataSource?.String
          ? payload.dataSource.String
          : null,
        suggestion_question: payload.suggestionQuestion?.String
          ? payload.suggestionQuestion.String
          : null,
        is_require_serial: payload.isRequireSerial,
        is_active: payload.isActive,
        isort: payload.isort?.Int32 ? Number(payload.isort.Int32) : null,
      });
      commit(MutationTypes.ADD_AGENT, agent);
      if (payload.tags) {
        payload.tags.forEach(async (tag) => {
          await httpRequest<TagModel>("POST", "linkAgentTag", {
            agent_id: agent.id,
            tag_id: tag.id,
          });
          commit(MutationTypes.UPDATE_AGENT_TAG, {
            agentId: agent.id,
            tagName: tag.name,
            isAdd: true,
          });
        });
      }
      return agent.id;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.GET_AGENT]({ commit }, id) {
    try {
      const agent = await httpRequest<AgentModel>("GET", `agent/${id}`);
      commit(MutationTypes.SET_AGENT, [agent]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LIST_AGENT]({ commit, state }, username) {
    commit(MutationTypes.SET_LOADING, true);
    try {
      if (state.agent.length > 0) {
        commit(MutationTypes.SET_AGENT, state.agent);
      } else {
        const agent = await httpRequest<AgentModel[]>("POST", "listAgent", {
          username,
          limit: 100,
          offset: 0,
        });
        commit(MutationTypes.SET_AGENT, agent);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    commit(MutationTypes.SET_LOADING, false);
  },
  async [ActionTypes.UPDATE_AGENT]({ commit }, agent) {
    try {
      const updatedAgent = await httpRequest<AgentModel[]>(
        "POST",
        "updateAgent",
        {
          id: agent.id,
          name: agent.name,
          description: agent.description?.String
            ? agent.description.String
            : null,
          api_url: agent.apiUrl,
          authorization_key: agent.authorizationKey,
          image_base64: agent.imageBase64?.String
            ? agent.imageBase64.String
            : null,
          data_source: agent.dataSource?.String
            ? agent.dataSource.String
            : null,
          suggestion_question: agent.suggestionQuestion?.String
            ? agent.suggestionQuestion.String
            : null,
          is_require_serial: agent.isRequireSerial,
          is_active: agent.isActive,
          isort: agent.isort?.Int32 ? Number(agent.isort.Int32) : null,
        }
      );
      commit(MutationTypes.UPDATE_AGENT, updatedAgent);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.DELETE_AGENT]({ commit }, id) {
    try {
      await httpRequest<AgentModel>("DELETE", "deleteAgent", {
        id,
      });
      commit(MutationTypes.DELETE_AGENT, id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // ROOMS
  async [ActionTypes.CREATE_ROOM]({ commit }, payload) {
    try {
      const room = await httpRequest<RoomModel>("POST", "createRoom", {
        username: payload.username,
        agent_id: payload.agentId,
      });
      commit(MutationTypes.SET_ROOM, room);
      const session = await httpRequest<SessionModel[]>("POST", "listSession", {
        room_id: room.id,
        limit: 100,
        offset: 0,
      });
      commit(MutationTypes.SET_SESSION, session);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // SESSIONS
  async [ActionTypes.LIST_SESSION]({ commit }, payload) {
    try {
      commit(MutationTypes.SET_LOADING, true);
      const session = await httpRequest<SessionModel[]>("POST", "listSession", {
        room_id: payload.roomId,
        limit: 100,
        offset: 0,
      });
      commit(MutationTypes.SET_SESSION, session);
      commit(MutationTypes.SET_LOADING, false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.CREATE_SESSION]({ commit }, payload) {
    try {
      const session = await httpRequest<SessionModel>("POST", "createSession", {
        room_id: payload.roomId,
        name: payload.name,
      });
      commit(MutationTypes.CREATE_SESSION, session);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPDATE_SESSION]({ commit }, payload) {
    try {
      console.log("update session");
      const session = await httpRequest<SessionModel>("PUT", "updateSession", {
        session_id: payload.sessionId,
        name: payload.name,
        serial: payload.serial,
        conversation_id: payload.conversationId,
      });
      commit(MutationTypes.UPDATE_SESSION, session);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.DELETE_SESSION]({ commit }, sessionId) {
    try {
      await httpRequest<ChatModel[]>("DELETE", "deleteSession", {
        session_id: sessionId,
      });
      commit(MutationTypes.DELETE_SESSION, sessionId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // FEEDBACKS
  async [ActionTypes.CREATE_FEEDBACK]({ commit }, payload) {
    try {
      const feedback = await httpRequest<ChatModel[]>(
        "POST",
        "createFeedback",
        {
          username: payload.username,
          chat_history_id: payload.chatHistoryId,
          is_like: payload.isLike,
          star: payload.star,
          comment: payload.comment,
        }
      );
      // commit(MutationTypes.SET_FEEDBACK, feedback);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LIST_FEEDBACK]({ commit }) {
    try {
      const feedback = await httpRequest<FeedbackModel[]>(
        "GET",
        "listFeedback"
      );
      commit(MutationTypes.SET_FEEDBACK, feedback);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // CHATS
  async [ActionTypes.LIST_CHAT]({ commit }, payload) {
    try {
      commit(MutationTypes.SET_LOADING, payload.isLoading);
      const chat = await httpRequest<ChatModel[]>("POST", "listChatHistory", {
        session_id: payload.sessionId,
        limit: 100,
        offset: 0,
      });
      commit(MutationTypes.SET_CHAT, chat);
      commit(MutationTypes.SET_LOADING, false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.CREATE_CHAT]({ commit }, payload) {
    try {
      const chat = await httpRequest<ChatModel[]>("POST", "createChatHistory", {
        session_id: payload.sessionId,
        text: payload.text,
        role: payload.roleText,
        image_url: payload.imageUrlString,
        preview_link: payload.previewLinkString,
      });
      commit(MutationTypes.UPDATE_CHAT, { chat, dummyId: payload.dummyId });
    } catch (error) {
      console.error("Error fetching data:", error);
      commit(MutationTypes.SET_BLOCKING_INPUT, false);
      commit(MutationTypes.DELETE_DUMMY_CHAT);
    }
  },
  async [ActionTypes.DUMMY_CHAT]({ commit }, chat) {
    try {
      commit(MutationTypes.DUMMY_CHAT, chat);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // TAGS
  async [ActionTypes.CREATE_TAG]({ commit }, name) {
    try {
      const tag = await httpRequest<TagModel>("POST", "createTag", {
        name,
      });
      commit(MutationTypes.CREATE_TAG, tag);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPDATE_TAG]({ commit }, tag) {
    try {
      const updatedTag = await httpRequest<TagModel>("POST", "updateTag", {
        id: tag.id,
        name: tag.name,
      });
      commit(MutationTypes.UPDATE_TAG, updatedTag);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LIST_TAG]({ commit }, username) {
    try {
      const tag = await httpRequest<TagModel[]>("POST", "listTag", {
        username,
      });
      commit(MutationTypes.SET_TAG, tag);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.SELECTED_TAG]({ commit }, tag) {
    try {
      commit(MutationTypes.SET_SELECTED_TAG, tag);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // AGENT TAGS
  async [ActionTypes.LINK_AGENT_TAG]({ commit }, payload) {
    try {
      await httpRequest<TagModel>("POST", "linkAgentTag", {
        agent_id: payload.agentId,
        tag_id: payload.tagId,
      });
      commit(MutationTypes.UPDATE_AGENT_TAG, {
        agentId: payload.agentId,
        tagName: payload.tagName,
        isAdd: true,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UNLINK_AGENT_TAG]({ commit }, payload) {
    try {
      await httpRequest<TagModel>("POST", "unlinkAgentTag", {
        agent_id: payload.agentId,
        tag_id: payload.tagId,
      });
      commit(MutationTypes.UPDATE_AGENT_TAG, {
        agentId: payload.agentId,
        tagName: payload.tagName,
        isAdd: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // ROLES
  async [ActionTypes.CREATE_ROLE]({ commit }, payload) {
    try {
      const role = await httpRequest<RoleModel>("POST", "createRole", {
        name: payload.name,
        description: payload.description,
        is_active: payload.isActive,
      });
      commit(MutationTypes.CREATE_ROLE, role);
      if (payload.agents) {
        payload.agents.forEach(async (agent) => {
          await httpRequest<RoleModel>("POST", "linkRoleAgent", {
            role_id: role.id,
            agent_id: agent.id,
          });
          commit(MutationTypes.UPDATE_ROLE_AGENT, {
            roleId: role.id,
            agentName: agent.name,
            isAdd: true,
          });
        });
      }
      return role.id;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LIST_ROLE]({ commit }) {
    try {
      const role = await httpRequest<RoleModel[]>("GET", "listRole");
      commit(MutationTypes.SET_ROLE, role);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UPDATE_ROLE]({ commit }, role) {
    try {
      const updatedRole = await httpRequest<RoleModel>("POST", "updateRole", {
        id: role.id,
        name: role.name,
        description: role.description,
        is_active: role.isActive,
      });
      commit(MutationTypes.UPDATE_ROLE, [updatedRole]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.DELETE_ROLE]({ commit }, id) {
    try {
      await httpRequest<RoleModel>("DELETE", "deleteRole", {
        id,
      });
      commit(MutationTypes.DELETE_ROLE, id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // ROLE AGENTS
  async [ActionTypes.LINK_ROLE_AGENT]({ commit }, payload) {
    try {
      await httpRequest<RoleModel>("POST", "linkRoleAgent", {
        role_id: payload.roleId,
        agent_id: payload.agentId,
      });
      commit(MutationTypes.UPDATE_ROLE_AGENT, {
        roleId: payload.roleId,
        agentName: payload.agentName,
        isAdd: true,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UNLINK_ROLE_AGENT]({ commit }, payload) {
    try {
      await httpRequest<RoleModel>("POST", "unlinkRoleAgent", {
        role_id: payload.roleId,
        agent_id: payload.agentId,
      });
      commit(MutationTypes.UPDATE_ROLE_AGENT, {
        roleId: payload.roleId,
        agentName: payload.agentName,
        isAdd: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // ROLE USER
  async [ActionTypes.LINK_ROLE_USER]({ commit }, payload) {
    try {
      await httpRequest<UserModel>("POST", "linkRoleUser", {
        username: payload.username,
        role_id: payload.roleId,
      });
      commit(MutationTypes.UPDATE_ROLE_USER, {
        username: payload.username,
        roleName: payload.roleName,
        isAdd: true,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.UNLINK_ROLE_USER]({ commit }, payload) {
    try {
      await httpRequest<UserModel>("POST", "unlinkRoleUser", {
        username: payload.username,
        role_id: payload.roleId,
      });
      commit(MutationTypes.UPDATE_ROLE_USER, {
        username: payload.username,
        roleName: payload.roleName,
        isAdd: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  // UTILS
  async [ActionTypes.SEARCH]({ commit }, search) {
    try {
      commit(MutationTypes.SET_SEARCH, search);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.LOADING]({ commit }, isLoading) {
    try {
      commit(MutationTypes.SET_LOADING, isLoading);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  async [ActionTypes.URL_METADATA](_, url) {
    try {
      const metadata = await httpRequest<MetadataModel>("POST", "urlMetadata", {
        url,
      });
      return metadata;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {
        title: "Open Link",
        thumbnail: "",
        baseUrl: url,
      };
    }
  },
};
